<template>
  <modal name="update-user" class="modal-inner modal-update-user" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <span class="close-button" @click="hide('update-user')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="updateInfo">{{ updateInfo.username }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateUser" v-if="updateInfo != null">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.firstname" type="text" :field_name="$t('user_modal.first_name')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.lastname" type="text" :field_name="$t('user_modal.last_name')" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.email" type="text" :field_name="$t('user_modal.email')" rules="required|email" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.phone" type="text" :field_name="$t('user_modal.phone')" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseInput v-model="updateInfo.username" type="text" :field_name="$t('user_modal.username')" rules="required|alpha_dash" disabled readonly />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2">
            <BaseSelect v-model="updateInfo.role" :options="filtered_roles" nameSelector="name" optionSelector="id" :field_name="$t('user_modal.role')" rules="required" @change="changeUserRole()" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="updateInfo.role != 'admin'"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2 py-2" v-if="/^reseller|^superuser|^user/.test(updateInfo.role) && user.role == 'admin'">
            <BaseSelect v-model="updateInfo.reseller_id" :options="resellers" nameSelector="name" optionSelector="_id" field_name="Återförsäljare" :rules="/^reseller/.test(updateInfo.role) ? 'required' : ''" @change="changeReseller()" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2" v-else-if="/^reseller/.test(updateInfo.role)">
            <BaseSelect v-model="updateInfo.reseller_id" :options="resellers" nameSelector="name" optionSelector="_id" field_name="Återförsäljare" :rules="/^reseller/.test(updateInfo.role) ? 'required' : ''" />
          </div>
          <div class="w-full md:w-1/3 flex flex-col px-2 py-2" v-if="updateInfo.role == 'superuser' || updateInfo.role == 'user'">
            <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("user_modal.customer") }}<b class="text-red-500 ml-1">*</b></div>
            <div class="w-full">
              <div class="mb-2">
                <input type="text" v-model.trim="customerSearch" placeholder="Sök" class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-2 py-2" />
              </div>
              <div class="max-h-80 overflow-y-scroll border border-gray-200 rounded px-2 py-2">
                <div v-if="filteredCustomer.length == 0" class="w-full text-xs italic text-gray-500 py-2">{{ $t("user_modal.no_cutomer_found") }}</div>
                <div class="px-2 transition ease-in-out duration-200" v-for="c in filteredCustomer" :key="c._id" v-bind:class="{ 'bg-gray-100': updateInfo.customers_id.indexOf(c.customer_id) > -1 }">
                  <label class="inline-flex items-center mt-2">
                    <input v-if="updateInfo.role == 'superuser'" v-model="updateInfo.customers_id" :value="c.customer_id" type="checkbox" class="form-checkbox h-4 w-4" />
                    <input
                      v-if="updateInfo.role == 'user'"
                      v-model="updateInfo.customers_id[0]"
                      :value="c.customer_id"
                      type="radio"
                      class="form-checkbox h-4 w-4"
                      @change="
                        filterUnitByCustomer();
                        updateInfo.units_id = [];
                      "
                    />
                    <span class="ml-2 text-sm font-sans font-semibold">{{ c.name }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 flex flex-col px-2 py-2" v-if="updateInfo.role == 'user'">
            <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("user_modal.operation") }}<b class="text-red-500 ml-1">*</b></div>
            <div class="w-full">
              <div class="mb-2">
                <input type="text" v-model.trim="unitSearch" placeholder="Sök" class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-2 py-2" />
              </div>
              <div class="max-h-80 overflow-y-scroll border border-gray-200 rounded px-2 py-2">
                <div v-if="filteredUnit.length == 0" class="w-full text-xs italic text-gray-500 py-2">{{ $t("user_modal.no_department_found") }}</div>
                <div class="px-2 transition ease-in-out duration-200" v-for="u in filteredUnit" :key="u._id" v-bind:class="{ 'bg-gray-100': updateInfo.units_id.indexOf(u.unit_id) > -1 }">
                  <label class="inline-flex items-center mt-2">
                    <input v-model="updateInfo.units_id" :value="u.unit_id" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-sm font-sans font-semibold">{{ u.name }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-2 py-2" v-if="updateInfo.role == 'alarm_center'">
            <BaseSelect v-model="updateInfo.alarm_center_id" :options="alarm_centers" nameSelector="name" optionSelector="_id" :field_name="$t('user_role.alarm_center')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2" v-if="updateInfo.role == 'seller'">
            <BaseSelect v-model="updateInfo.seller_id" :options="sellers" nameSelector="name" optionSelector="_id" :field_name="$t('user_role.seller')" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2 py-2" v-if="updateInfo.role == 'tech'">
            <BaseSelect v-model="updateInfo.tech_id" :options="technicians" nameSelector="name" optionSelector="_id" :field_name="$t('user_role.tech')" rules="required" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('update-user')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateUser()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 800;

export default {
  props: {
    userInfo: {
      required: true,
      default: null,
    },
    roles: {
      required: true,
    },
    allCustomers: {
      required: true,
    },
    allUnits: {
      required: true,
    },
    alarm_centers: {
      required: true,
    },
    resellers: {
      required: true,
    },
    sellers: {
      required: true,
    },
    technicians: {
      required: true,
    },
  },
  data() {
    return {
      updateInfo: null,
      customerSearch: "",
      unitSearch: "",
      filtered_roles: [],
      customers: [],
      units: [],
      customer_units: [],
    };
  },
  watch: {
    userInfo(newVal) {
      // eslint-disable-line
      this.updateInfo = _.cloneDeep(newVal);

      this.customerSearch = "";
      this.unitSearch = "";
      this.customer_units = [];
      if (this.updateInfo.role == "superuser" || this.updateInfo.role == "user") {
        if (this.updateInfo.reseller_id != "" && this.user.role == "admin") this.getResellerCustomers();
        this.filterUnitByCustomer();
      }
    },
    allCustomers(newVal) {
      this.customers = _.cloneDeep(newVal);
    },
    allUnits(newVal) {
      this.units = _.cloneDeep(newVal);
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    filteredCustomer() {
      var vm = this;
      let c = _.filter(this.customers, function(o) {
        return o.name.toLowerCase().includes(vm.customerSearch.toLowerCase()) || vm.updateInfo.customers_id.indexOf(o.customer_id) > -1;
      });
      return c;
    },
    filteredUnit() {
      var vm = this;
      let u = _.filter(this.customer_units, function(o) {
        return o.name.toLowerCase().includes(vm.unitSearch.toLowerCase()) || vm.updateInfo.units_id.indexOf(o.unit_id) > -1;
      });
      return u;
    },
  },
  methods: {
    async updateUser() {
      this.$refs.formUpdateUser.validate().then(async (success) => {
        if (!success) {
          return;
        }
        if (this.updateInfo.role == "user" && this.updateInfo.units_id.length == 0) {
          this.handleError(null, "Ingen verksamhet vald");
          return;
        }
        if (this.updateInfo.role == "superuser" && this.updateInfo.customers_id.length == 0) {
          this.handleError(null, "Ingen kund vald");
          return;
        }

        if (this.updateInfo.role != "superuser" && this.updateInfo.role != "user") this.updateInfo.customers_id = [];
        if (this.updateInfo.role != "user") this.updateInfo.units_id = [];
        if (this.updateInfo.role != "alarm_center") this.updateInfo.alarm_center_id = "";
        if (this.updateInfo.role != "reseller" && this.updateInfo.role != "superuser" && this.updateInfo.role != "user") this.updateInfo.reseller_id = "";
        if (this.updateInfo.role != "seller") this.updateInfo.seller_id = "";
        if (this.updateInfo.role != "tech") this.updateInfo.tech_id = "";

        let data = {
          firstname: this.updateInfo.firstname,
          lastname: this.updateInfo.lastname,
          username: this.updateInfo.username.toLowerCase(),
          email: this.updateInfo.email,
          phone: this.updateInfo.phone,
          role: this.updateInfo.role,
          // customer_id: this.updateInfo.customer_id,
          customers_id: this.updateInfo.customers_id,
          units_id: this.updateInfo.units_id,
          alarm_center_id: this.updateInfo.alarm_center_id,
          reseller_id: this.updateInfo.reseller_id,
          seller_id: this.updateInfo.seller_id,
          tech_id: this.updateInfo.tech_id,
        };

        try {
          // eslint-disable-next-line
          let response = await this.axios.put(`${process.env.VUE_APP_SERVER_URL}/user/${this.updateInfo._id}`, data);
          this.$emit("updateUser");
          this.$modal.hide("update-user");
        } catch (error) {
          let error_message = "Något problem uppstod";
          if (error.response && error.response.status == 400) error_message = "Otillräckliga behörigheter";
          this.handleError(error, error_message);
        }
      });
    },

    filterUnitByCustomer() {
      if (this.updateInfo.customers_id.length == 0) return;

      let c = _.find(this.customers, { customer_id: this.updateInfo.customers_id[0] });
      if (c) this.customer_units = _.filter(this.units, { parent_id: c._id });
      else this.customer_units = [];
    },

    changeUserRole() {
      this.updateInfo.reseller_id = "";
      this.changeReseller();
    },

    changeReseller() {
      if (/^superuser|^user/.test(this.updateInfo.role)) {
        if (this.updateInfo.reseller_id != "") {
          this.getResellerCustomers();
        } else {
          this.customers = _.cloneDeep(this.allCustomers);
          this.units = _.cloneDeep(this.allUnits);
        }

        this.updateInfo.customers_id = [];
        this.updateInfo.units_id = [];
        this.customer_units = [];
      }
    },

    getResellerCustomers() {
      var vm = this;

      this.units = _.filter(this.allUnits, { seller_id: this.updateInfo.reseller_id });
      let customerIDs = _.uniq(_.map(this.units, "parent_id"));
      this.customers = _.filter(this.allCustomers, function(o) {
        return customerIDs.indexOf(o._id) > -1 || o.seller == vm.updateInfo.reseller_id;
      });
    },

    filterRoles() {
      if (this.user.role != "admin")
        this.filtered_roles = _.filter(this.roles, function(o) {
          return o.id == "superuser" || o.id == "user";
        });
      else this.filtered_roles = this.roles;
    },

    resetForm() {
      this.$refs.formUpdateUser.reset();
      this.customers = _.cloneDeep(this.allCustomers);
      this.units = _.cloneDeep(this.allUnits);
      this.filterUnitByCustomer();
    },

    hide(id) {
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    this.filterRoles();
  },
};
</script>
